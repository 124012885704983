function Struggle() {
  return (
    <div className="struggle sec-p">
      <h2 className="struggle__title">
        ARE YOU FEELING ANY SYMPTOMS OF <span className="struggle-span">WRITER'S BLOCK</span>
      </h2>
    </div>
  );
}

export default Struggle;
